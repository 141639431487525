:root {
    --cube-width: 90px;
}
@keyframes spin {
    from {
      transform: rotateY(0deg) rotateX(0deg);
    }
    to {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }
  
  #shared-loader {
    box-sizing: border-box;
    font-family: sans-serif;
    width: var(--cube-width);
    height: var(--cube-width);
    margin: 0;
    perspective: calc(var(--cube-width) * 4);
    transform: scale(0.2);
    margin-left: -25px;

  }
      
  .cube {
    width: calc(var(--cube-width)*2);
    height: calc(var(--cube-width)*2);
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(calc(- var(--cube-width)));
    transition: transform 2s;
    animation: spin 6s linear infinite;
  }
  
  .cube-face {
    position: absolute;
    width: calc(var(--cube-width)*2);
    height: calc(var(--cube-width)*2);
    border: 2px solid #fff;
    line-height: calc(var(--cube-width)*2);
    font-size: 80px;
    font-weight: bold;
    color: white;
    text-align: center;
    background: #de662c;
    background-color: #de662c ;
    background-image: url("/public/glogo-glow.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .cube-face-front  { transform: rotateY(  0deg) translateZ(calc(var(--cube-width))); background-color: #3370F7 !important; }
  .cube-face-right  { transform: rotateY( 90deg) translateZ(calc(var(--cube-width))); background-color: #5BA2E3 !important;}
  .cube-face-back   { transform: rotateY(180deg) translateZ(calc(var(--cube-width)));background-color: #96A3B0 !important; }
  .cube-face-left   { transform: rotateY(-90deg) translateZ(calc(var(--cube-width)));background-color: orange !important; }
  .cube-face-top    { transform: rotateX( 90deg) translateZ(calc(var(--cube-width)));background-color: #375E85 !important; }
  .cube-face-bottom { transform: rotateX(-90deg) translateZ(calc(var(--cube-width)));background-color: #3370F7 !important; }