body {
  background: #1d1d29;
  overflow-x: hidden !important;
}
.layout-dashboard-container {
  border: 2px solid;
  box-shadow: 0 0 15px #000;

  max-width: 1900px;
  min-width: 1700px;
  position: relative;
  border-color: #898BB466 !important;
  background-color: #000; 
  border-top: 0;
  border-bottom: 0;
  display: flex;
  padding: 0 !important;
  flex-flow: column;
  }
  .dash-main-container {
    padding: 80px 35px 35px 35px;
  
  }
  .dash-main-container[data-menuopened="true"] {
    padding-left: 100px;
  }
  .general-box {
    width: 100%;
    border-radius: 10px; 
  }
  .general-box-color {
    background-color: #191932aa;
  }
  .yesterday-container {
    max-width: 578px;
  }
.dash-top-merchants-list .row {
  margin-bottom: 10px;
}
.max-400 {
  max-width: 400px;
}
.topXbar div {
  display: inline-block;
 
  height: 18px;
}
.thumb-vertical {
  position: relative;
    display: block;
    width: 100%;
    height: 30px;
    transform: translateY(0px);
    opacity: 0.4;
    background-color: #fff;
}
.thumb-vertical:hover {
  opacity: 1;
}
.dash-col-right {
  padding-top: 30px;
}
 
.dash-sessioninfo-icon {
  background: red;
    margin-left: 10px;
    border-radius: 15px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rotated-gauge { 
  transform: rotate(-90deg);
}
.dash-tophubs-content .dash-side-header {
  margin-left: 20px;
  font-weight: bold;
}

.globe-container {
  top: -101px;
  z-index: 0;
  position: absolute;
  right: 130px;
  width: 850px;
}

.globe-container video {
  position: absolute;
  top: 136px;
  left: 0px;
  width: 100%;
  Z-INDEX: -1;
}


.orders-per-minute{
  font-size: 16px;
    margin-left: 8px;
    color: yellow;
}

.highlight-amount {
  color:yellow;
}
.dash-sessioninfo-icon-container  {
  position: relative;
}
.menu-item:hover {  
  background-color: orange ;
  color:#000;
}
.menu-item  {
  gap: 10px;
  padding:8px 8px 8px 14px;
  cursor: pointer;
  border-radius: 10px;
  flex: 1 100%;
  max-height: 50px;
  background-color: #000000dd;
  border:1px solid #fff;
  margin-bottom: 8px;
  align-items: center;
  position: absolute;
  transition: top .5s, opacity .5s, background-color 0.2s, color 0.2s;
  left:0;
  right: 0;
}

.remove-menu {
  display: none;
}
.dash-sessioninfo-content {
padding-top: 15px; 
  font-size: 20px;
  margin-left: -10px;
}
.dash-sessioninfo-number {
  font-size: 45px;
  align-items: baseline;
}

.dash-yesterday-content {
padding-left: 8px;
width: 100%;
margin: 5px 0 10px 0;
min-height: 110px;
padding-right: 8px;
flex-flow: column;
display: flex;
}
.dash-tophubs-content {
  padding-left: 20px;
}
.stock-mini-change {
  font-size: 13px;
  margin-left: 5px;
  align-items: center;
}
[data-positive="true"] {
  color: #00ff15;
}
[data-positive="false"] {
  color: #ff0000;
}
.dash-yesterday-amount {
  font-size: 32px;
  font-weight: bold;
}
.fg.dash-yesterday-amount {
  font-size: 45px;
  font-weight: bold;
}
.prepost-title 
{
  color: #A090B2;
  font-weight: bold;
  margin-right: 8px;
}
.stock-mini-price {
  font-size: 35px;
  font-weight: bold;
  align-items: baseline;
  margin-top: 5px;
}
.yesterday-container .dash-yesterday-count {
  font-size: 22px !important;
}
.dash-yesterday-count {
  font-size: 16px;
  display: flex;
  flex-grow: 0;
  align-items: baseline; 
}
.zero-opacity {
  opacity: 0;
  pointer-events: none; 
}
.release-notes-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000000;
  background-color: #000000cc;
  align-items: center;
  justify-content: center;
}
.release-notes-changes {
  gap: 15px;
  margin-top: 30px;
  padding-right: 25px;
}
.release-note-header {
  font-size: 20px;   
  font-style: italic;
}
.release-notes-box {
  position: relative;
  width: 800px;
  min-height: 500px;
  border:1px solid white;
  border-radius: 10px;
  padding: 40px;
  background-color: #1A1931;
}
.release-notes-title {
  color:yellow;
  font-size: 24px;
}
.release-note-dismiss {
  margin-top: 20px;
  color:yellow
}
[data-hidden="true"] {
  display: none !important;
}
.yesterday-avg-diff {
  font-size: 14px;
  line-height: 100%;
}
.yesterday-avg-diff[data-positive="true"] {
  color: #00ff15 !important;
}
.yesterday-avg-diff[data-positive="false"] {
  color: #ff0000 !important;
}
.estimation-container {
  width: 100%;
  xborder:1px solid #898BB466;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  xbackground-color: #189ad333;
  font-size: 20px;
  color:#b0a2c1;
  gap:10px;
  padding-bottom: 0;
}
.estimation-container .estimation-icon {
  margin-right: 10px;
}
.estimation-container .estimation-number {
  margin-left: 10px;
  color:yellow;
}
.close-release-notes {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  font-size: 25px;

}
.merchants-filter-container {
  margin-right: 16px;
  margin-left: auto !important;
}
.minheight_200{
  min-height: 400px !important;
}
.change-icon {
  margin-right: 10px;
}
.dash-side-header {
  color:#A090B2;
  font-size:20px;
  line-height: 100%;
  margin-top: 5px;

}
.channel-breakdown-count {
  margin-left: auto;
  margin-right: 8px;
}
.channel-breakdown {
  margin-top:8px;
  padding-right: 8px;
  font-size: 18px;
}
.channel-breakdown-amount {
  color:yellow;
}
.channel-breakdown-amount[data-offline="true"] {
  color:red !important;
}
.fg.dash-side-header {
  color:#A090B2;
  font-size:30px;
}
.topXbar div:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
[data-positive="true"] .stock-price-perc-diff {
  color: #00ff15;
}
[data-positive="false"] .stock-price-perc-diff {
  color: #ff0000;
}
.stock-details {
  flex: 100%;
  width: 100%; 
  margin-top: 35px;
  margin-left: 52px;
  font-size: 16px;
}
.stock-details-label {
  width: 155px;
  color: #DEE2E6aa;
}
.stock-price-perc-diff {
  font-size: 35px;
}
.stock-price-inner-container {
  display: flex;
  align-items: flex-end
}
.stock-graph-container {
  max-height: 215px;
}
.stock-price-num {
  line-height: 100%;

  font-size: 70px;
  padding:0;
  margin: 0 8px 0 0 ;
}
.stock-price-inner-container div {
  display: flex;
  align-items: flex-end
}
.stock-price {
  flex: 100%;
  max-height: 75px;
  width: 100%;
  justify-content: space-between;
  padding: 0 30px 0 30px;
 
}
.dashboard-menu {
   
  width: 400px;
  height: 200px;
  font-size: 21px;
  padding: 10px;
  top: 50px;
  z-index: 1000;
  position: absolute;
}
.topXbar div:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.main-charts-container .bi {
  font-size: 31px;
  margin-right: 15px;
  color:#fff;
}
.merchants-count-index {
  width:50px;
 
  text-align: center;
   margin-right: 5px;
   border-right: 2px solid #383748;
}
.dash-merchants-info {
  min-height: 500px !important;
  padding: 20px !important; 
}
.dash-merchants-list {
  height: 95%;
}
.dash-merchant-row {
  display: flex; 
  align-items: center;
  font-size: 18px;
 
   transition: opacity 0.2s;
}
.mrt-mountime {
  font-size: 13px;
  color:#42b7fb;
}
.dash-merchant-row-total {
  border-top: 3px solid #898BB466;
  background-color: #898bb430;
  padding: 8px 0;
  color: yellow;
  border-bottom: 3px solid #898BB466;
}

.dash-merchant-row-total .dash-ocount-container {
  color:white !important;
}

.dash-merchant-row-total .dash-aov {
  color:white !important;

}

[data-issearchresult="true"] {
  opacity: 1 !important;
}
[data-ischild="true"] .merchants-count-index {
  height: 30px;
  border-left: 1px dashed;
  border-bottom: 1px dashed;
  border-right: 0;
  border-color: #333;
  width: 20px;
  margin-left: 14px;
}
[data-ischild="true"]:first-child {
  border-top: 1px solid #333;
}
[data-ischild="true"]:last-child {
  border-bottom: 1px solid #333;
}
.dash-merchant-row[data-childrenvisible="true"] + .merchant-child-container {
    display: block;
}

.merged-merchant-icon {
  margin-left: 13px;
}
.dash-merchant-row:hover {
  cursor:default;
  color:yellow !important;
  background-color: #7D93AA44;
}
.merchant-child-container .dash-merchant-row {
  margin-left: 34px;
}
.no_pointer_events {
  pointer-events: none;
}
.channel-breakdown-name {
  cursor: default;
}
.merchant-child-container {
  display: none;
}
.globe-gl-interaction {
  height: 900px; 
  position: absolute;
  z-index: 0;
}
.globe-gl-container {
  height:  985px;
  position: absolute;
  overflow: hidden;
  margin-top: -60px;
}

.merchant-search-container {
  position: relative; 
}
 .merchant-search-container input {
    background-color: #191932;
    color:#fff;
  
 }
.clear-search-text {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(-50%, -50%);
}
 .merchant-search-container input::placeholder {
  color: #888;
  opacity: 1; /* Firefox */
}
.merchant-search-container input:visited,  .merchant-search-container input:focus {
  background-color: #191932;
  color:#fff;
}
.dash-aov {
  color:orange
}
  .globe-info {
    position: absolute;
    right: -28px;
    top: -5px;
    z-index: 3;
    height: 270px;
    width: 300px;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,1) 62% ,rgba(0,0,0,0)), linear-gradient(to bottom, rgba(0,0,0,1) 62%, rgba(0,0,0,0));
    -webkit-mask-size: 100% 50%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: top, bottom;
  
  }
  .prepost-price {
    font-size: 25px;
    margin-right: 10px;
  }
  .prepost-change[data-positive="true"] {
    color: #00ff15;
  }

  .prepost-change[data-positive="false"] {
    color: #ff0000;
  }
  .stock-details-value {
    align-items: baseline;
    line-height: 100%;
  }
  .dv2-header {
    xfont-size: 40px;
    z-index: 1;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
  }
  .percent.lower {
    color: #ff0000;
  }
  .percent.higher , .merchant {
    color: #00ff15;
    display: inline-block;
  }
  .money, .amount{
    color : yellow;
  }
  .ticker_contaner {
    margin-right: 20px;
  }
  .reset-view-container {
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
  .count {
    color: lightskyblue;
  }
   .dv2-time {
    margin-top: -24px;
    margin-left: 7px;
    font-size: 13px;
  }
  .dash-aov-container {

  }
  .dash-ocount-container {
    width: 70px;
    text-align: center;
  }
  .dash-amount-container {
    width: 120px;
  }
  .dv2-merchant-container {
    gap: 8px;
  }
 .dash-sessioninfo-root {
    margin-left: -20px;
 }
 .checkout-net-conversion {
  margin-left: 8px;
  font-size: 18px;
  color:#00ff15
 }
 .si-perc-diff {
  margin-left: 8px;
  font-size: 18px;
  color:#00ff15
 }
 .si-perc-diff[data-positive="false"] {
  color:#ff0000
 }
 [data-show="false"] {
  display: none;
 }
 .ticker-container {
  position: relative;
  overflow: hidden;
  max-width: 580px;
  min-width: 580px;
  height: 25px;
  white-space: nowrap; /* Prevents text from wrapping */
 }
.stock-ticker {
  display: flex;
  gap: 4px;
  justify-content: end;  
  min-width: 300px;
  justify-content: end;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1111111111;  
  animation: tickerMove linear infinite;
}

.stock-ticker .bi-coin {
  margin:0 15px 0 15px
}
  .dv2-insight-container {
    position: absolute;
    left: 14px;
    font-size: 28px;
    font-style: italic;
    max-width: 650px;
    border-radius: 10px;
    background: #00000077;
    padding: 20px;
    bottom: 85px;
    transition: opacity 2s;  
  }
  .order-data-container {
    min-width: 600px;
    gap: 8px;
    width: 50%;
    flex-grow: 1;
    max-width: 600px;
    margin-bottom: 8px;
  }
  [data-isalertactive="true"] {
    filter:blur(4px);
  }

.db-topwidgets-left {
  display: flex;
  flex-grow: 0;
}

.db-topwidgets-right {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  gap: 8px;
}

  .alerts-container {
    width: 100%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    right: 0;
    bottom: 0;
    height: 100vh;
    align-items: center;
    padding-top: 40px;
    justify-content: top;
    flex-direction: column;
    gap: 20px;
    
  }
  .alret-title .bi {
    margin-right: 13px;    
  }
  .alert-content {
    font-size: 28px;
    padding: 30px;
  }
  .alret-title {
    font-size: 35px;
    font-weight: bold;
    background-color: rgb(87, 6, 6);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #000;
    padding: 15px;
  }
  .alert-close {
    cursor: pointer;
  }
  .alert-inner-container {
    width: 50%;
    border-radius: 10px; 

    border:1px solid #000;
    background-color: rgba(172, 67, 67, 0.8);
  }
  .stock-mini-container {
    flex-grow: 1;
    border-color: #898BB466 !important;
  
    xmin-height: 150px !important;
  }
  .order-data-container .general-box {
    margin: 0 !important;
    min-width: 200px;
  }
  .order-pace-infos {
    font-size: 12px;
  }
  .stock-details-row {
    width: 100%;
    min-height: 30px;
  }
  .dv2-pie {
    animation: rotate-animation 10s infinite linear;
  }
  .order-pace-box {
    margin-bottom: -55px;
  }
  .unique-sessions-title {
    font-size: 17px;
    color: #DEE2E6aa;
  }

  .stock-title{
    font-size: 17px;
    color: #DEE2E6aa;
    width: 100%;
    padding: 10px 0 0 15px;

  }
 
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .unique-sessions-title {
    margin:0;
    padding-left: 10px;
    padding-top: 10px;
  }

  .unique-sessions-title.weekly-trends {
    padding-left: 5px;
    padding-top: 5px;
  }
 .main-icon {
  margin-right: 12px;
  font-size: 40px;
 }
  .layout-pie {
    width: 240px;
    position: relative;
    border-radius: 100%;    
  }
  .svg-small {
    font-size: 3px;
  }
  .dash-side-header .bi {
    margin-right: 6px;
    color:#bebe6b;
  }
  .rt-icon {
    margin-right: 5px;
  }
  .pie-title .bi {
    margin-right: 8px;
  }
  .stock-info-left,.stock-info-right {
    flex-flow: column;
    justify-content: flex-start;
  }

  .pie-title {
    position: absolute;
    left: 15px;
    top: 8px;
  }
  .stock-widget-inner {
    flex-flow: column;
    height: 100%;
    width: 100%;
    margin: 0 20px 20px 20px ;
    
  }
  .stock-widget-box {
    max-width: 50%;
    flex-basis: calc(50% - 4px);
  }
.stock-widget-box[data-visible="false"] {
  display:none !important;
}
  .worldmap-container[data-fullwidth="true"] {
    max-width: none;
    flex-basis: 100%;
  }
  .worldmap-container {
    justify-content: center;
    padding: 20px;
    flex: 0 1 50%;
    height: 500px;
    max-width: 50%; 
    border: 1px solid #41425C!important;
    background-color: #000!important;
    flex-basis: calc(50% - 4px);
  }
  .worldmap-title {
    position: absolute;
    left: 15px;
    top:15px;
    xwidth: 100%;
  }
  .worldmap-container svg {
    border-radius: 10px;
  }
  .worldmap-container svg:focus {
    outline: none;
  }
  .pies-container  {
    gap: 8px;
    align-items: baseline;
    width: 100%;
  }
.order-sales-info {
  flex:1;  
}
.order-data-custom-text {
  padding: 10px;
}
  .dash-box {
    border-color: #898BB466 !important;
    border-radius: 10px;   
    background-color: #191932aa;
    justify-content: flex-start;
    flex-flow: column;
    align-items: center;
  }

  .db-pies-inner-container {
    position: relative;
    border-color: #898BB466 !important;
    border-radius: 10px;   
    background-color: #191932aa;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 40px 0 20px 0;    
    flex-flow: column;
    align-items: center;
    height: 100%;
  }
  .piecell_label {
    flex-grow: 1;
  }
  .piecell_percentage, .piecell_abs {
    width: 50px;
    align-items: end;
    justify-content: end;
  }
  .dbpie-table-container {
    margin-top: 20px;
    width: 100%;
    padding: 0 30px 0 30px;
  }
  .worldmap__figure-container {
    xbackground-color: #000 !important;
  
  }
  
  .dbpie-row {
    border-bottom: 1px solid #A090B233;
    margin-bottom: 4px;
  }
  .dbpie-row:last-child {
    border-bottom: 0;
  }
  .dbpie-row[data-top="true"] {
    color:yellow;
  }

  .dbpie-row[data-others="true"] {
    color: #A090B2;
  }
 .top-expend {
  padding-right: 20px;
  cursor: pointer;
 }
 .top-expend .bi {
   color:rgb(0, 255, 21);
 }
  .main-charts-container  {
    padding-left: 20px !important;
  }
.gauges-top-container {
  justify-content: end;
  align-items: end;
  width: 100%;
}
.yesterday-container {
  width: auto;
  flex-grow: 1;
  border-color: #898BB466 !important;

}
 
  .gmvbox-container {
    max-width: 600px;
  }
  .dv2-lefttop-container {
    width: 100%;
    z-index: 1;
    margin-top: -70px;
  }
 
  
  .test-scroll {
     height: auto !important;
  }
  canvas {
    max-width: 100%;
  }
  .dv2-leftcolumn {
    flex: 1 1; 
  }
  .xdayssales-container {
    padding: 25px !important;
   
  }
  .weekly-trends-container {
    flex: 0 1 100%;
    z-index: 5;
    gap:8px;
    xmargin-top: 8px;
  }
  .xdayssales-title {
    margin-left: 10px;
  }
  .lastXdays-sales {
    margin-top: 8px;
  }
   
.dash-top-merchants-list {
  font-size: 20px;
  padding: 0;
}
.dash-top-merchants-list .bi {
  font-size: 13px;
}
.dash-gmv-amount {
  font-size: 90px;
}
.dash-width-100 {
  -ms-flex: 0 0 100px;
  flex: 0 0 100px;
}
.topcountry-sep {
  flex-grow: 1;
  align-items: center;
}
.dash-width-200 {
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
}
.dash-width-250 {
  -ms-flex: 0 0 250px;
  flex: 0 0 250px;
}
.dash-width-150 {
  -ms-flex: 0 0 150px;
  flex: 0 0 150px;
}
.dash-width-50 {
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}
.dv2-insight-container[data-enabled="false"] {
  display: none !important;
}
.dash-width-20 {
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
}
.globe-gl-interaction .bg-image {
  display: none;
}

.full-globe-order-counter-container {
  position: absolute;
    right: 20px;
    min-width: 500px;
    top: 20px;
}
.rt-info {
  gap: 4px;
}
.rt-p-diff .bi {
  font-size: 14px;
}
.rt-p-diff[data-positive="true"] {
  color: #00ff15;
}
.rt-p-diff[data-positive="false"] {
  color: #ff0000;
}
.full-globe-order-counter-container .dash-yesterday-amount {
  font-size: 50px;
}
.globe-gl-container[data-enabled="true"] .bg-image {
  display: none;;
}
.globe-gl-container[data-enabled="false"] .bg-image {
  display: inline;;
}
.merchant-title-text {
  color: #A090B2;
}
.ai-warning {
  font-size: 13px;
    margin-top: 30px;
    color: rgb(160, 144, 178);
}   
.sessions-data-container {
  flex-grow: 1;
  width: 50%;
}
.error-title {
  font-size: 30px;
}
.error-reconnect {
  font-size: 21px;

}
.error-container { 
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.root-alerts-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.stockchartloaderror {
  height: 126px;
    width: 100%;
    justify-content: center;
    color: red;
}

.dash-fullscreen .tm-amounts {
  display: none !important;
}

[data-visible="false"] { 
   opacity: 0;
}
[data-visible="true"] { 
  opacity: 1;
}
.dashboard-menu-title {
  font-size: 12px;
}
  @media only screen and (max-width: 800px) {
    .dashboard-menu-title {
      display: none !important;
    }
    .dash-top-merchants-list {
      font-size: 16px;
    }
    .dash-main-container {
      padding: 0px !important;
    }
    .map-row {
      flex-direction: column !important;
    }
    .stock-mini-price {
      font-size: 35px;
    }
    .yesterday-container {
      max-width: none;
      min-width: 100% !important;
      margin-bottom: 0 !important;
      min-height: 140px !important;
    }
    .stock-mini-container {
      width: 100% !important;
      max-width: none;
      margin-bottom: 0 !important;
    }
    .stock-mini-change {
      font-size: 13px;
    }
 
    .gauges-top-container {
      flex-wrap: wrap!important;
      justify-content: center;
    }
    .dv2-merchant-container {
      flex-wrap: wrap!important;
    }
    /* html, body {
      overflow: hidden;
      overflow-y: auto;
    } */
    .mobile-hide {
      display: none;
    }
    .loadertxt {
      text-align: center;
    }
    .dash-gmv-amount {
      font-size: 55px;
    }
    .zero-opacity {
      display: none;
    }
    .weekly-trends-container {
      flex-wrap: wrap!important;
      gap:0 !important;
    }
    .max-400 {
      max-width: none;
    }
    .dv2-leftcolumn {
      width: 100% !important;
    }
    .mobile-stretch-box {
     width: 100%;
    } 
    .dash-merchant-row {
      font-size: 14px;
    }
    .checkout-net-conversion {
      margin-left: 0 !important;
      padding-left: 3px !important;
    }
    .release-notes-box {
 
      width: 90%;
 
    }
    .trends-header-container {
      align-items: baseline !important;
    }
    .trends-header-text {
      max-width: 50% !important;

    }
    .dash-gmv-count {
      flex-flow: column!important;
    }
    .fps-container  {
      width: 100% !important;
    }
    .dash-tophubs-content .dash-side-header {
      
    }
    .estimation-container {
      font-size: 15px !important;
    }
    .loadingSplash {
      height: 100vh;
      align-items: center;
      justify-content: center;
      width: 100vw;
     background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%) !important;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
}
 .weekly-trend-container {
    flex: 1 100% !important;
 }
 .amount-small {
  font-size: 35px !important;
 }
 .pie-box-container {
  width: 300px !important;
 }
 .ticker_contaner {
  margin: 0 !important;
  position: absolute;
  top: 3px;
  font-size: 13px;
 }
 .dv2-header {
  font-size: 30px;
  margin-left: 10px;
  flex-flow: column;
 }
 .ticker-container {
  max-width: 100vw;
  min-width: 100vw;
 }
 .dashboard-page {
  padding: 0px 10px 30px 20px !important; 
 }
 .error-message {
  width: 80%;
  text-align: center;
 }
 .gmvbox-container  {
  max-width: none !important; 
 }
 .orders-sessions-container , .orders-data-container, .sessions-data-container {
  flex-direction: column !important;
 }
 .sessions-data-container {
  width: 100% !important;
 }
 .orders-sessions-container {
  gap: 8px !important;
 }
 .dashed-line {
  margin-top: 10px !important;
 }
 .channel-indicator {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
 }
[data-native="1"]  .dv2-header-container {
  flex-grow: 1!important;
  margin-bottom: 8px;
  margin-top: 40px;
  margin-left: 15px;
}
.dv2-header-container {
  left: 10px !important;
  padding: 10px 0 10px 0 !important;
  top:-3px !important;
  background-color: rgba(0, 0, 0, 0.8);
}
.merchant-search-container {
  flex: 1 100%;
}
.order-data-container {
  min-width: 100% !important;
  flex-flow: column-reverse !important;
  margin-bottom: 0 !important;
}
.merchants-header-row {
  flex-wrap: wrap!important;
  max-height: 140px !important;
  flex-grow: 0 !important;
  gap: 7px;
  margin-bottom: 12px;
}
.merchants-filter-container {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.dash-topmenu-menuitem, .szh-menu__submenu {
 
  font-size: 20px !important;
}
.dash-topmenu-menuitem-hover,.szh-menu__item:hover {
  font-size: 20px !important;
}
.gauges-mobile-container , .gmv-inner-container {
 
  background-color: #191932aa;
  margin-bottom: 8px;
}
.dash-gmv-count {
  font-size: 29px;
  margin-top: -20px;
  margin-left: -2px;
}
.error-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
.userComp  {
  font-size: 16px !important;
}
.error-reconnect {
  font-size: 15px;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
}
.error-reconnect-text {
  display: flex;
  width: 60%;
}
.weekly-title-mobile {
  flex-wrap: wrap!important;
}
 .dv2-time {
  margin-top: -20px;
  margin-left: 7px;
 }
 .unique-sessions-title {
  font-size: 14px;
 }
    .layout-pie {
    margin-right: 0;
    }
    .layout-dashboard-container {
    align-self: auto;
    margin: 0;
    padding: 5px !important;
    min-width: auto;
    }

 .layout-pie {
  align-self: center !important;
  margin-left: 0 !important;
 }
 .xdayssales-title {
  margin-left: -10px !important;
  margin-top: -10px !important;
 }
 .trends-switch-container {
  margin-right: 0 !important;
}
 .dv2-lefttop-container {
  margin-top: 20px;
 }
 .merchants-count-index {
  display: none;
 }
 .db-pies-inner-container {
  flex-direction: column!important;
  align-items: center;
  height: auto;
  width: 100%;
 }
 .dash-tophubs-content {
  padding: 5px !important;
 }
 .tm-percent {
  display: none;
 }
 .tm-amounts {
  flex: 1;
 }
 .diff-small {
  font-size: 14px !important;
 }
 .GMVPercentChange {
  font-size: 17px !important;
 }
.globe-gl-interaction {
  pointer-events: none;
}
.globe-gl-interaction[data-insightsenabled="false"] {
  display: none !important;
}
.dv2-insight-container  {
  top: 29px;
  right: 0;
  left: 0;
  font-size: 17px;
   bottom: 0;
}
.login-com-container .login { 
position: absolute !important;
    top: 20px !important; 
    padding: 40px;
    left: 0 !important;
    margin: auto !important;
    width: 100%  !important;
    height: 460px  !important;
    border-radius: 20px;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}
.custom-messages {
  margin:auto !important;
  margin-top: 50px !important;
  left: 10px !important;
  right: 10px !important;
}
.ai-warning {
  margin-top: 15px;
}
.dash-side-menu {
  padding-top: 5px !important;
}
.dashboard-menu {
  width: 100%;
}
[data-native="1"] .dashboard-menu {
  top:75px
}
.dash-amount-container {
  padding-right: 5px !important;
  width: 90px;
}
.hubs-list {
  padding-left: 20px !important;
  padding-right: 0 !important;
  padding-top: 10px !important;
}
.tm-amounts div:first-child  {
  justify-content: end !important;
  margin-right: 10px;
}
.unique-sessions-title-text {
      justify-content: left;
    flex-flow: wrap;
    width: 80%;
}
.dash-sidemenu-item {
  height: 80px !important;
}
.dash-aov-container, .dash-ocount-container {
  display: none !important;
}
.tm-amounts div:last-child {
  display: none !important;
}
.topcountry-sep {
  display: none !important;
}
 .globe-gl-container {
  position: relative;
  height: 250px !important; 
  margin-top: 40px !important;
 }
 .top-order-pace-container {
  margin-top: 50px;
 }
 .globe-gl-container[data-enabled="false"] {
  height: fit-content !important;
 }
 .globe-gl-interaction {
  height: auto;
  position: relative;
  min-height: 225px;
}
.globe-gl-interaction[data-enabled="false"] .bg-image {
  display: inline;
 }
 .globe-gl-container .bg-image {
  display: none !important;
 }
 .globe-gl-interaction[data-enabled="true"] .bg-image {
  display: none;
 }
 .stock-info-left, .stock-info-right {
  width: 100% !important;
 }
 .stock-price-perc-diff {
  font-size: 28px;
 }
 .worldmap-container, .box-template {
  width: 100%;
  flex-grow: 1 !important;
  min-height: 200px;
  max-width: none !important;
 }
 .dash-row {
  max-height: fit-content !important;
 }
 
}
.release-notes-scroll {
  min-height: 280px;
}
.loadingSplash {
  height: 100vh;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%) !important;
 
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 64px;
  height: 64px;
  position: relative;
  animation: rotate 1.5s ease-in infinite alternate;
}
.loader::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  color: #FF3D00;
  background: currentColor;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
}
.loader::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 10%;
  background: #FFF;
  width: 8px;
  height: 64px;
  animation: rotate 1.2s linear infinite alternate-reverse;
}

@keyframes rotate {
  100% { transform: rotate(360deg)}
}
      


.tm-amounts div {
  flex-grow: 1;
}
.merchants-header-row {

}
.tm-amounts div:first-child {
  justify-content: flex-start;
}
.tm-amounts div:last-child {
  justify-content: flex-end;
  flex-grow: 0;
  align-items: baseline;
}
.loader-text {
  margin-top: 20px;
  font-size: 30px;
}
.center-flex {
  align-items: center;
  justify-content: center;
}
.dash-row {
 height: fit-content;
 flex-grow: 1;
 margin-top: 8px;
 max-height: 500px;
 gap:8px;
}

.merchants-header-row  {
  flex-basis: fit-content;
}

.pie-box-container {
  width: 400px;
 }

/* HTML: <div class="loader"></div> */
.loadertxt {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background: linear-gradient(135deg,#fff0 calc(50% - 0.5em),#fff 0 calc(50% + 0.5em),#fff0 0) right/300% 100%;
  animation: l22 2s infinite;
}
.loadertxt::before {
  content: "Loading dashboard v3.0a...";
  color: #fff0;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(135deg,#fff calc(50% - 0.5em),#000 0 calc(50% + 0.5em),#fff 0);
  -webkit-background-clip:text;
          background-clip:text;
}
#editor-container {
  --jse-theme-color: #191932aa;
  --jse-theme-color-highlight: #f553b5;
  --jse-font-size: 24px;
  --jse-font-size-mono: 24px;
  --jse-background-color: #1d1d29;
  --jse-key-color: #fff !important;
  --jse-delimiter-color: #fff;
  --jse-value-color-string:#37eb37;
  --jse-value-color-number:#ff9084;
  --jse-panel-background:#191932aa;
  --jse-panel-color-readonly:#fff;
 
}
.globe-point {
  background-color: yellow;
  border-radius: 100%;
  box-shadow: 0 0 3px 1px #FFFF00,0 0 3px 1px #fff,0 0 6px 1px #FFFF00;
  width: 4px;
  height: 4px;
  pointer-events: none;

}
.json-readonly {
  display: none !important;
}
@keyframes l22{
  100%{background-position: left}
}
.login-input {
  width: 350px;
}
.loader-text-login {
  margin:15px 0 15px 0;
  font-size: 23px;
}
.dash-gmv-count {
  align-items: baseline;
}
.checkout-conv-icon {
  font-size: 17px;
  margin-left: 5px;
}
.login-box {
  align-items: center;
  justify-content: center;
}
.login-box[data-loginrequired="false"]{
  display: none !important;
}
.monthly-box {
  background-color: #189ad333!important
}
.login-box[data-loginrequired="true"]{
  display: flex !important;
}
.bi-fullscreen {
  cursor: pointer;
}
.login-message {
  color: red;
    font-size: 19px;
    text-align: center;
    margin-top: 10px;
}
.dash-fullscreen {
  padding: 0;
}
.dash-globe-fullsceen-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.roller-main-text {
  font-size: 260%;
  font-weight: bold;
}
.error-message[data-iserror="true"] {
  display: block;
  color:red;
  font-size: 19px;
}
.error-message[data-iserror="false"] {
  display: none;
}

.text-animate .flag {
  width: 131px;
  min-height: 85px;
  margin-left: -10px;
  margin-bottom: 15px;
} 

.text-static {
  left:190px !important; 
  opacity: 1;
}



.text-animate {
  display: flex !important;
  position: absolute;
  left: -900px;
  font-size: 110%;
  top: 150px; 
  width: 900px;
  height: 280px;
  z-index: 100;
  transition:opacity 2s, left 2s;
  font-family: roboto;

}

.roller-country {
  color: #FC6900 !important;
 
}
.roller-time {
  font-size: 140%;
}
.roller-amount {
  font-size: 220%;
}

.GMVPercentChange {
  font-size: 28px;
  margin-bottom: 15px;
}
.GMVPercentChange[data-positive="true"] {
  color: #00ff15;
}
.GMVPercentChange[data-positive="false"] {
  color: #ff0000;  
}
 
.text-animate-in {



  animation-name: fadein;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

}

@keyframes fadein {
  0% {left: -900px;opacity: 0;top:150px;}
  100% {left:190px; opacity: 1;top:150px;}
}

@keyframes fadeout {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.full-globe-session-info-container {
  position: absolute;
  bottom: 50px;
  left: 150px;
}

[data-menuopened="false"] .full-globe-session-info-container {
  left: 50px;
}
.full-globe-session-info-container .dash-sessioninfo-number {
  font-size: 170%;
}

.full-globe-session-info-container .dash-sessioninfo-content {
  font-size: 25px;
  
}

.full-globe-session-info-container .scene-container {
  height: 100vh !important;
}

.dash-fullscreen .gauges-container {
  max-width: 1200px !important;
}

.dash-gmv-amount .bi {
  margin:0 3px -3px 3px;
}

.error-loader {
  width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color:#de3500 #0000 #fff #0000;
    border-radius: 50%;
    box-sizing: border-box;
    animation: 1s rotate linear infinite;
}
.error-loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color:#de3500 #0000 #fff #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.error-loader:before , .error-loader:after{
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color:#fff;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.error-loader:after {
  border-color: #de3500 #0000 #0000 #0000 ;
  transform: translate(32px, 3px) rotate(-35deg);
}
 @keyframes rotate {
  100%{    transform: rotate(360deg)}
}

.dash-side-menu  {
  min-width: 0px;
  opacity: 0;
  xoverflow: hidden;
  min-height: 100vh;
  flex-flow: column;
  background-color: #191932;
  z-index: 100000;
  border-right: 1px solid #373847;
  xpadding-top: 20px;  
  position: fixed;
  pointer-events: none;
  justify-content: center;
  top:0;
  bottom: 0;
}
.monthly-container .monthly-count {
  font-size: 18px !important;
}
.dash-side-menu-logo {
  justify-content: center;
  height: 64px;
}
.dash-side-menu.open {
  min-width: 90px;
  opacity: 1;
  pointer-events: all;
 
}

.dash-side-menu-items {
  justify-content: end;
    height: 100vh;
    display: flex;
    flex-flow: column;
}
.dash-sidemenu-item {
  height: 100px;
  width: 100%;
  font-size: 55px;
  justify-content: center;
  cursor: pointer;
  color:rgba(147, 133, 163, 0.5);
  position: relative;
  align-items: center;

}
.dash-sidemenu-item:hover {
   color: #fff;
}
.menuitem-disable {
  position: absolute; 
  font-size: 16px;
  color:red;
  font-weight: bold;
  left: 70%;
  top: 45%;
  display: none;
  transform: translate(-50%,-50%);
}
.dash-sidemenu-item[data-logicalstate="ENABLED"] {
      color: rgb(199, 199, 205);

}
.dash-sidemenu-item[data-logicalstate="DISABLED"] {
  color:rgba(147, 133, 163, 0.5);
}
.dash-sidemenu-item[data-logicalstate="ENABLED"]:hover {
  color:#fff;

}
.dash-sidemenu-item[data-logicalstate="DISABLED"]:hover {
   color:#fff;
}

.fps-warning {
    color: red;
    flex-wrap: wrap;
    text-wrap: wrap;
    overflow-wrap: break-word;
    display: flex;
    padding-right: 20px;
}
.fullscreen-main-container {
  width: 100%;
  padding-left: 20px;
}
.fps-text[data-visible="false"] {
  display: none;
}
.fps-text[data-warning="true"] {
  color: red;
}
.fps-text {
  font-size: 22px;
}
.fps-container {
    position: absolute;
    width: 620px;
    min-height: 50px;
    z-index: 111;
    left: 20px;
    top: 0;
    font-weight: bold;
 
}
.login-container {
  padding: 50px;
}
.fps-container[data-background="true"] {
  background-color: #000000cc;
}
.login-required-message {
      text-align: center;
    font-size: 24px;
}
.dwh-yesteray-breackdown {
  display: flex;
  flex-flow: column;
  font-size: 12px;
  /* margin-bottom: 10px; */
}
.dwh-bd-row-seperator {
  border-bottom: 1px solid #898BB466;
  margin: 5px 0 10px 0;
}
.dwh-bd-row {
  display: flex;
  justify-content: space-between;
}
.dwh-sumcount {
  display: flex;
  gap:10px
}
.dv2-header-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10000;
  padding-left: 20px;
  background-color: #000000dd;

  padding-top: 12px;
  padding-bottom: 12px;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%,  rgba(255,255,255,0) 50%);
  background-color: rgba(0, 0, 0, 0.8);
}
[data-menuopened="true"] .dv2-header-container {
  left: 100px !important;
}
 
.dash-fullscreen .dv2-header-container  {
  z-index: 1000;
  position: fixed !important;
  padding-left: 0 !important;
}


.custom-messages {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: start;
  justify-content: left;
  margin-left: 150px;
  margin-top: 56px;
  z-index: 111111;
  pointer-events: none;
  transition:  opacity .5s;
}

.custom-messages__message { 
  width: 800px;
  border:1px solid #807894;
  padding: 20px;
  border-radius: 10px;
  background-color: #191932aa;
  color:yellow
}
.custom-messages__message__item {
  font-size: 20px;
  display: flex;
}
.custom-messages__message__item .bi {
  margin-right: 10px;
}
.custom-messages__message__item[data-type="warning"] {
  color: #FF3D00;
  font-weight: bold;
}
.custom-messages__message__item[data-type="info"] {
  color: #00ff15;
}
.custom-messages[data-visible="false"] {
  display: none;
}

.logos-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s
}

.logos-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: #000;
}
.tv-mode-toggle {
  position: absolute;
  right: 50px;
  top:30px;
  z-index: 1000000000;
  font-size: 50px;
  cursor: pointer;
}

.tv-mode-toggle[data-enabled="true"] {
  color:#00ff15;
}
cy {
  color:yellow;
}
.channel-indicator {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  color:#fff;  
  margin-left: 8px;
  font-size: 11px;
 font-weight: bold;
 line-height: 100%;
}
.filter-button {
  background: #9F90B0;
    color: #000;
    border: 0;
}
.filter-menu-item {
  display: flex;
  align-items: center;
}
.filter-menu-item .selected-icon {
  margin-right: 8px;
}
.filter-menu-item[data-selected="true"] .selected-icon {
   visibility: visible;

}
.filter-menu-item[data-selected="false"] .selected-icon {
  visibility: hidden;

}

.channel-indicator[data-channel="BF"] {
  background-color: #3370F7;
  color:#fff;
}
.channel-indicator[data-channel="MP"] {
  background-color: BLUE;
}
.logo_animated {
  animation-duration: .3s;
  animation-name: stepped-pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(4, end);
}
 
@keyframes stepped-pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.trends-switch-container {
  margin-right: 8px;
}
.system-ai-messages {
  color:yellow;
  cursor: pointer;
}
.dash-switch .bi {
  font-size: 18px;
}

.test {
  background-color: red;
}
/* CSS file */
.szh-menu {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgba(255,255,255,0.1) !important;
  background-color: #23262C !important;
  font-family: 'Roboto', sans-serif !important;
 
}

.menu-icon {
  margin-right: 15px;

}

.dash-topmenu-menuitem, .szh-menu__submenu {
  color: #fff;
  background-color: #23262C !important;
 
}

.dash-topmenu-menuitem-hover,.szh-menu__item:hover {
  color: #fff;
  background-color: #32363C !important;
}
.dash-topmenu-menuitem[aria-disabled="true"] {
  color: rgb(170, 170, 170) !important;
background-color: #23262C !important;
  cursor:default;
}
.szh-menu__item--open {
  background-color: #32363C !important;
}
.menu-icon-tv {
  font-size: 44px;
}

.dashboard-page {
  padding: 120px 120px 30px 80px;
  width: 100%;
}

.release-notes-page-container {
  margin-top: 40px;
}
.release-notes-page-version {
  margin-bottom: 80px;
}
.version-title {
  font-size: 40px;
  color: #0060EF;
}
.release-note-page-item-header h3{
  padding: 0;
    margin: 0;
    padding-left: 8px;
}
.login-general-message {
  font-size: 17px;
  color: #fff;
  margin-top: 20px;

}
.userComp {
    width: 36px;
    height: 36px;
    border-radius: 100%;
     margin: 0 10px 0 0 !important; 
    font-weight: bold;
}
.login-com-container .login { 
position: absolute;
    top: 50%;
    left: 50%;
    margin: -230px 0 0 -250px;
    width: 500px;
    height: 460px;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.4);
    border: 1px solid #222;
    padding: 50px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.login-com-container .login h1 { color: #fff; text-shadow: 0 0 10px rgba(0,0,0,0.3); letter-spacing:1px; text-align:center; }
.login-com-container input { 
	width: 100%; 
	margin-bottom: 10px; 
	background: rgba(0,0,0,0.8);
	border: none;
	outline: none;
	padding: 10px;
	font-size: 13px;
	color: #fff;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 4px;
	box-shadow: inset 0 -5px 45px rgba(100,100,100,0.2), 0 1px 1px rgba(255,255,255,0.2);
	-webkit-transition: box-shadow .5s ease;
	-moz-transition: box-shadow .5s ease;
	-o-transition: box-shadow .5s ease;
	-ms-transition: box-shadow .5s ease;
	transition: box-shadow .5s ease;
}
.login-com-container input:focus { box-shadow: inset 0 -5px 45px rgba(100,100,100,0.4), 0 1px 1px rgba(255,255,255,0.2); }
/* .login-com-container .btn1 { display: inline-block; *display: inline; *zoom: 1; padding: 4px 10px 4px; margin-bottom: 0; font-size: 13px; line-height: 18px; color: #333333; text-align: center;text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75); vertical-align: middle; background-color: #f5f5f5; background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6); background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6); background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6)); background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6); background-image: -o-linear-gradient(top, #ffffff, #e6e6e6); background-image: linear-gradient(top, #ffffff, #e6e6e6); background-repeat: repeat-x; filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0); border-color: #e6e6e6 #e6e6e6 #e6e6e6; border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); border: 1px solid #e6e6e6; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); cursor: pointer; *margin-left: .3em; } */
.login-com-container .btn1:hover, .btn1:active, .btn1.active, .btn1.disabled, .btn1[disabled] { background-color: #e6e6e6; }
.login-com-container .btn1-large { padding: 9px 14px; font-size: 15px; line-height: normal; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }
.login-com-container .btn1:hover { color: #333333; text-decoration: none; background-color: #e6e6e6; background-position: 0 -15px; -webkit-transition: background-position 0.1s linear; -moz-transition: background-position 0.1s linear; -ms-transition: background-position 0.1s linear; -o-transition: background-position 0.1s linear; transition: background-position 0.1s linear; }
.login-com-container .btn-primary.active { color: rgba(255, 255, 255, 0.75); }
.login-com-container .btn-primary { background-color: #4a77d4;  border:0;border-radius: 5px; }
 
.login-com-container .btn-block { width: 100%; display:block; }
.login-com-container .btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] { filter: none; background-color: #4a77d4 !important; }
.login-com-container * { -webkit-box-sizing:border-box; -moz-box-sizing:border-box; -ms-box-sizing:border-box; -o-box-sizing:border-box; box-sizing:border-box; }
.login-com-container .btn-primary, .btn-primary:hover { text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important; color: #ffffff !important; }
.login-com-container .btn-okta, .btn-okta:hover { text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important; color: #ffffff !important; }
.login-com-container .btn-okta { background-color: #dd811f; border:0;border-radius: 5px;}


.admin-login-body {
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104,128,138,.4) 10%,rgba(138,114,76,0) 40%), linear-gradient(to bottom, rgba(57,173,219,.25) 0%,rgba(42,60,87,.4) 100%), linear-gradient(135deg, #670d10 0%,#092756 100%);
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 100vh;
  ;
}
.globe-zoom-container {
  cursor: pointer;
  border:1px solid #fff;
 
 
  border-radius: 10px;
  position: absolute;
  right: 630px;
  bottom: 20px;
  z-index:1000000000 ;
  user-select: none;
  background-color: #191932aa;
  border-color: #898BB466 !important;
}
.user-managemenet .MuiSvgIcon-root {
  color: #fff;
}
.user-id-col {
  min-width: 600px;
}
.user-name-col {
  min-width: 250px;
}

.user-input {
  max-width: 300px;
}
.user-admin-col {
  min-width: 150px;
}
.user_api_token textarea {
  width: 100%;
  height: 100px;
  background: #222;
  color: #fff;
  padding: 13px;
  max-width: 1200px;
}
.zoom-icon[data-disabled="true"] {
  opacity: 0.3;
}
.zoom-icon {
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background .7s

}
.zoom-icon:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.zoom-icon:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.zoom-icon:hover {
  background: #3A6794;
 
}
.users-paging {
  margin-top: 15px;
  gap: 5px;
}
.user-page-button.active {
  background: orange !important;
  border:1px solid orange !important;

}
.users-row {
  align-items: center;
}
.users-row .btn,.users-row .MuiButton-root {
  font-size: 12px;
}

.users-adduser-container{
  
  flex-grow: 0;
  margin-top:40px;
  padding: 20px;
  border:1px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  gap:10px;
}

.alert-active {
  animation-name: blink-animation; /* Name of the animation */
  animation-duration: .5s; /* Duration of the animation */
  animation-iteration-count: infinite; /* Repeat the animation indefinitely */
  animation-direction: alternate; /* Alternate between start and end states */
}

@keyframes blink-animation {
  from {
    color: initial; /* Use the element's original color */
  }
  
  to {
    color: red;
  }
}

.amount-small 
{
  font-size: 32px;
}
.diff-small {
  font-size: 12px;
}

.dashed-line {
  display: flex;
  flex-grow: 1;
  border-bottom: 1px dashed #ffffff33;
  margin-top: 13px;
  margin-left: 4px;
}

.dashed-line-container {
  display: flex;
    flex-grow: 1;
}